import React, { useEffect, useState } from 'react';
import { Assignment, DailyTaskEvents } from '../../../types/types';
import { getDailyAssignmentTaskEvents } from '../../../utils/requests';

import ActivityGraph, { DataDisplayType } from './ActivityGraph';
import { TEST_ENV } from '../../../utils/constants';

interface Props {
  assignment: Assignment;
}

function AssignmentActivityCard({ assignment }: Props): JSX.Element {
  const [dailyTaskEvents, setDailyTaskEvents] = useState<DailyTaskEvents[]>([]);
  const [dataDisplay, setDataDisplay] = useState<DataDisplayType>('TASKS');

  useEffect(() => {
    if (assignment.assignmentId) getDailyAssignmentTaskEvents(assignment.assignmentId, setDailyTaskEvents);
  }, [assignment.assignmentId]);

  if (TEST_ENV) return <></>;
  return (
    <div id="activity-card" className="card">
      <div className="heading">
        <h2>Activity</h2>
        <span className="ctrls">
          <label htmlFor="dataDisplay-select">Show:</label>
          <select
            id="dataDisplay-select"
            name="dataDisplay"
            value={dataDisplay}
            onChange={(e) => setDataDisplay(e.target.value as DataDisplayType)}
          >
            <option value="TASKS">Tasks</option>
            <option value="PERCENT">Percent</option>
          </select>
        </span>
      </div>
      <ActivityGraph height={128} assignment={assignment} dailyTaskEvents={dailyTaskEvents} dataDisplay={dataDisplay} />
    </div>
  );
}

export default AssignmentActivityCard;
